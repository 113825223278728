import React from "react";
import "./Contactme.css";

export default function Contactme() {
  return (
    <div>
      <div className="contact-me-container">
        <div className="contact-me-title">
          <h3>Contact Me</h3>
        </div>
        <div className="email-container">
          <p>
            Email :{" "}
            <a href="muhitlopez143@gmail.com">muhitlopez143@gmail.com</a>
          </p>
        </div>
        <div className="icon-link-container">
          <div className="github">
            <a href="github.com/musarratChowdhury">
              {" "}
              <i className="fa-brands fa-github"> </i>
            </a>
          </div>
          <div className="youtube">
            <a href="https://youtube.com/channel/UCVjQmbN8hINpEb_kylWh2sg">
              <i className="fa-brands fa-youtube"></i>
            </a>
          </div>
          <div className="linkedin">
            <a href="https://www.linkedin.com/in/musarrat-chowdhury-9506351a6/">
              <i className="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
