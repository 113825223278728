import React from "react";
import "./Project.css";

export default function Project({ name, src, alt, link, isVideo }) {
  return (
    <div className="project-Wrapper">
      <div className="project-name">
        <p>{name}</p>
      </div>
      <div className="project-image">
        {isVideo ? (
          <video
            className="object-contain "
            width={350}
            height={250}
            src={src}
            autoPlay
            loop
            preload="metadata"
            muted
          ></video>
        ) : (
          <img src={src} alt={alt} />
        )}
      </div>
      <div className="project-play-button">
        <div id="play-button">
          <a href={link}>Play</a>
        </div>
      </div>
    </div>
  );
}
