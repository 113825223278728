import React from "react";
import "./App2.css";
import About from "./components/About";
import Contactme from "./components/Contactme";
import Projects from "./components/Projects";

// import "./default.css";

export default function App2() {
  return (
    <section className="portfolio-wrapper">
      <section className="portfolio-intro">
        <div className="title">
          <h2 className="name">Musarrat Chowdhury</h2>
          <h4 className="occupation">HTML5 Game Developer</h4>
        </div>
        <div className="image-container">
          {/* <div className="lottie-anims">
            <lottie-player
              src="https://assets8.lottiefiles.com/packages/lf20_q7uarxsb.json"
              background="transparent"
              speed="1"
              loop
              autoplay
            ></lottie-player>
          </div> */}
          <div className="image-container-div">
            <img className="my-image" src="./images/portrait.png" alt="mydp" />
          </div>
        </div>
        <About />
      </section>
      <section className="projects-showcase">
        <Projects mobileView={detectMob()} />
      </section>
      <section className="certificate-showcase"></section>
      <section className="contact-me">
        <Contactme />
      </section>
    </section>
  );
}
function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
