import { useEffect, useRef } from "react";

import "./App.css";
import Piece from "./canvas/Piece";
import Tetriminos from "./canvas/tetriminos";
// import Canvas from "./canvas/canvas";

const pieces = [];
const NewPieceInterval = 2;
let MAX_ARR_LENGTH = 6;
let lastRenderTime = 0;
//
let PIECES = [
  [Tetriminos.Z, "#c23866"],
  [Tetriminos.S, "#c5e5ed"],
  [Tetriminos.T, "#f0c808"],
  [Tetriminos.J, "#f3ba64"],
  [Tetriminos.L, "#adff2f"],
  [Tetriminos.O, "#00bff3"],
  [Tetriminos.I, "#e03d21"],
];

function App() {
  // const [balls, setBalls] = useState([]);
  const canvasRef = useRef(null);
  const ctx = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    canvas.style.width = `${window.innerWidth}px`;
    canvas.style.height = `${window.innerHeight}px`;
    canvas.style.backgroundColor = "white";

    //
    //
    const context = canvas.getContext("2d");

    context.textAlign = "center";

    //mobile device
    if (detectMob()) {
      MAX_ARR_LENGTH = 10;
      Piece.COL = window.innerWidth / Piece.squareSize;
      canvas.style.height = `${window.innerHeight + 200}px`;
    } else {
      Piece.squareSize = 20;
      //
      Piece.COL = window.innerWidth / Piece.squareSize;
      window.addEventListener("resize", () => {
        canvas.style.width = `${window.innerWidth}px`;
        canvas.style.height = `${window.innerHeight}px`;
        Piece.COL = window.innerWidth / Piece.squareSize;
      });
    }

    //

    //draw an image

    //
    const animate = (currentTime) => {
      requestAnimationFrame(animate);

      //
      const secondsSinceLastRender = (currentTime - lastRenderTime) / 1000;
      if (secondsSinceLastRender >= 1 / NewPieceInterval) {
        lastRenderTime = currentTime;
        //
        let randomN = Math.floor(Math.random() * PIECES.length);
        let apiece = new Piece(PIECES[randomN][0], PIECES[randomN][1]);
        apiece.activeTetromino =
          apiece.tetromino[Math.floor(Math.random() * 4)];
        apiece.x = Math.floor(
          Math.random() * (Piece.COL - (apiece.activeTetromino.length - 1))
        );
        apiece.y = -2;

        setTimeout(() => {
          pieces.push(apiece);
        }, 0);

        if (pieces.length > MAX_ARR_LENGTH) {
          setTimeout(() => {
            pieces.shift();
          }, 0);
        }
        // console.log(pieces);
      }

      //
      context.fillStyle = "#36454F"; //charcoal
      context.fillRect(0, 0, window.innerWidth, window.innerHeight);

      pieces.forEach((piece, i) => {
        piece.y += 0.1;
        piece.draw(context);
      });
    };
    animate();
    //

    ctx.current = context;
  }, []);

  const startDrawing = ({ nativeEvent }) => {};

  const finishDrawing = ({ nativeEvent }) => {};

  const draw = ({ nativeEvent }) => {};

  //

  //
  return (
    <div className="background-animation">
      <canvas
        onMouseDown={startDrawing}
        onMouseUp={finishDrawing}
        onMouseMove={draw}
        ref={canvasRef}
      />
    </div>
  );
}

export default App;
function detectMob() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
