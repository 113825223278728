import React, { useState } from "react";
import Project from "./sub-components/Project";
import "./Projects.css";

export default function Projects({ mobileView }) {
  const [projects, setProjects] = useState([
    {
      name: "Snooker",
      src: "./images/snooker.jpg",
      alt: "snooker game",
      link: "https://snooker.muhit.dev/",
      id: -1,
      mobileOptimized: false,
      isVideo: false,
    },
    {
      name: "Twin Bar",
      src: "./images/portfolio-0.png",
      alt: "twinbar",
      link: "https://hardcore-mcclintock-8e562f.netlify.app/",
      id: 0,
      mobileOptimized: true,
      isVideo: false,
    },
    {
      name: "Brick Breaker",
      src: "./images/portfolio-1.jpg",
      alt: "brickbreaker",
      link: "https://dxball.muhit.dev/",
      id: 1,
      mobileOptimized: false,
      isVideo: false,
    },
    {
      name: "Quiz & Jump",
      src: "./images/portfolio-2.png",
      alt: "quizandJump",
      link: "https://boring-yonath-c9226e.netlify.app/",
      id: 2,
      mobileOptimized: true,
      isVideo: false,
    },
    {
      name: "Beat Catcher",
      src: "./images/portfolio-3.png",
      alt: "beatcatcher",
      link: "https://priceless-newton-fda99b.netlify.app/",
      id: 3,
      mobileOptimized: true,
      isVideo: false,
    },
    {
      name: "Car Race",
      src: "./images/portfolio-4.png",
      link: "https://hardcore-goldstine-98cc8c.netlify.app/",
      alt: "carrace",
      id: 4,
      mobileOptimized: true,
      isVideo: false,
    },
    {
      name: "Find & Pick",
      src: "./images/portfolio-5.png",
      link: "https://zealous-leakey-fd43d0.netlify.app/",
      alt: "find&pick",
      id: 5,
      mobileOptimized: true,
      isVideo: false,
    },
    {
      name: "Drawing App",
      src: "./videos/drawingapp.mp4",
      alt: "drawingapp",
      link: "https://musarratdrawingapp.netlify.app",
      id: 6,
      mobileOptimized: false,
      isVideo: true,
    },
    {
      name: "Snake",
      src: "./videos/snake.mp4",
      alt: "snake",
      link: "https://new-snake-game-js.netlify.app",
      id: 7,
      mobileOptimized: false,
      isVideo: true,
    },
    {
      name: "Treasure Chase",
      src: "./videos/treasureChase.mp4",
      alt: "treasurechase",
      link: "https://elated-bose-f96c5d.netlify.app/",
      id: 8,
      mobileOptimized: false,
      isVideo: true,
    },
    {
      name: "Iron Man Game",
      src: "./videos/IronManGame.mp4",
      alt: "ironman",
      link: "https://musing-allen-a43f3a.netlify.app",
      id: 9,
      mobileOptimized: false,
      isVideo: true,
    },
    {
      name: "Sky Defender",
      src: "./videos/animation-game.mp4",
      alt: "skydef",
      link: "https://frosty-shockley-fa6286.netlify.app",
      id: 10,
      mobileOptimized: false,
      isVideo: true,
    },
    {
      name: "Platform Game",
      src: "./videos/platformGame.mp4",
      alt: "platformgame",
      link: "https://mario-game-canvas-js.netlify.app/",
      id: 11,
      mobileOptimized: false,
      isVideo: true,
    },
  ]);
  return (
    <div className="projects-list-container">
      <div className="projects-list-title">
        <h4>Featured Projects</h4>
        <h6>Some of my previous projects</h6>
      </div>
      <div className="projects-list-wrapper">
        <ul className="projects-list">
          {mobileView
            ? projects.map(
                (project) =>
                  project.mobileOptimized && (
                    <li key={project.id}>
                      <Project
                        name={project.name}
                        src={project.src}
                        alt={project.alt}
                        link={project.link}
                        isVideo={project.isVideo}
                      />
                    </li>
                  )
              )
            : projects.map(
                (project) =>
                  !project.mobileOptimized && (
                    <li key={project.id}>
                      <Project
                        name={project.name}
                        src={project.src}
                        alt={project.alt}
                        link={project.link}
                        isVideo={project.isVideo}
                      />
                    </li>
                  )
              )}
        </ul>
      </div>
    </div>
  );
}
