import React from "react";
import "./About.css";

export default function About() {
  return (
    <div className="about">
      <p>
        <i className="fa-solid fa-quote-left"></i>
        Hi there! I am a self-taught programmer with a lots of passion for
        javascript programming. I make interesting and fun games on{" "}
        <b>HTML5 canvas</b> using Vanilla.js ,Also with libraries like{" "}
        <b>Phaser.js</b>, <b>P5.js</b>, <b>Create.js</b>.
        {/* I am also good with
        the 3D javascript library,
        <b>Three.js</b> */}
        <i className="fa-solid fa-quote-right"></i>
      </p>
    </div>
  );
}
